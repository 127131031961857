@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Space+Grotesk:wght@600&family=Inter:wght@400;600&display=swap');
@font-face {
  font-family: 'SFMonoRegular';
  src: url('../src/assets/fonts/SFMonoRegular.eot');
  src: url('../src/assets/fonts/SFMonoRegular.eot') format('embedded-opentype'),
    url('../src/assets/fonts/SFMonoRegular.woff2') format('woff2'),
    url('../src/assets/fonts/SFMonoRegular.woff') format('woff'),
    url('../src/assets/fonts/SFMonoRegular.ttf') format('truetype'),
    url('../src/assets/fonts/SFMonoRegular.svg#SFMonoRegular') format('svg');
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: 'SFMonoSemibold';
  src: url('../src/assets/fonts/SFMonoSemibold.eot');
  src: url('../src/assets/fonts/SFMonoSemibold.eot') format('embedded-opentype'),
    url('../src/assets/fonts/SFMonoSemibold.woff2') format('woff2'),
    url('../src/assets/fonts/SFMonoSemibold.woff') format('woff'),
    url('../src/assets/fonts/SFMonoSemibold.ttf') format('truetype'),
    url('../src/assets/fonts/SFMonoSemibold.svg#SFMonoSemibold') format('svg');
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "VCR_OSD_MONO_1_001";
  src: url("../src/assets/fonts/VCR_OSD_MONO_1_001.eot");
  src: url("../src/assets/fonts/VCR_OSD_MONO_1_001.eot") format("embedded-opentype"),
    url("../src/assets/fonts/VCR_OSD_MONO_1_001.woff2") format("woff2"),
    url("../src/assets/fonts/VCR_OSD_MONO_1_001.woff") format("woff"),
    url("../src/assets/fonts/VCR_OSD_MONO_1_001.ttf") format("truetype"),
    url("../src/assets/fonts/VCR_OSD_MONO_1_001.svg#VCR_OSD_MONO_1_001") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
.font-vcr {
  font-family: "VCR_OSD_MONO_1_001", sans-serif !important;
}

/* Core */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

body {
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  text-rendering: optimizeLegibility;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.fs-12, .fs-15 {
  line-height: 20px;
  font-weight: 400 !important;
  font-family: "VCR_OSD_MONO_1_001", sans-serif !important;
}

.fs-12 {
  font-size: 12px;
}
.fs-15 {
  font-size: 16px;
}

.w-full {
  width: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* .container {
  margin: 0 auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

[class^="col-"] {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-auto{flex:0 0 auto;width:auto;max-width:none;margin-left: auto; margin-right: auto;}
.col-1{flex:0 0 8.33333%;max-width:8.33333%}.col-2{flex:0 0 16.66667%;max-width:16.66667%}.col-3{flex:0 0 25%;max-width:25%}.col-4{flex:0 0 33.33333%;max-width:33.33333%}.col-5{flex:0 0 41.66667%;max-width:41.66667%}.col-6{flex:0 0 50%;max-width:50%}.col-7{flex:0 0 58.33333%;max-width:58.33333%}.col-8{flex:0 0 66.66667%;max-width:66.66667%}.col-9{flex:0 0 75%;max-width:75%}.col-10{flex:0 0 83.33333%;max-width:83.33333%}.col-11{flex:0 0 91.66667%;max-width:91.66667%}.col-12{flex:0 0 100%;max-width:100%} */

.app-paperhanded {
  max-width: 68%;
  margin: 0 auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
}
.app-paperhanded-results {
  min-height: auto;
}

.intro-banner {
  padding: 0;
  width: 100%;
  max-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}
.intro-banner-inner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-height: 275px;
  text-align: left;
  position: absolute;
}
.intro-banner-inner > img {
  width: 100%;
  max-height: 275px;
  object-fit: cover;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .intro-banner,
  .intro-banner > img {
    min-height: 200px;
    max-height: 200px;
  }
  .intro-banner img {
    object-fit: cover;
    object-position: 33.3333% center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .intro-banner,
  .intro-banner > img {
    min-height: 235px;
    max-height: 235px;
  }
  .intro-banner img {
    object-fit: cover;
    object-position: 33.3333% center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .intro-banner,
  .intro-banner > img {
    min-height: 260px;
    max-height: 260px;
  }
  .intro-banner img {
    object-fit: cover;
    object-position: 33.3333% center;
  }
}

.intro-banner-mobile {
  height: 220px;
  max-height: 220px;
  display: none !important;
}
.intro-banner-mobile img {
  height: 220px;
  max-height: 220px;
  object-fit: cover;
  object-position: 54% center;
}

@media (max-width: 767px) {
  .intro-banner {
    display: none !important;
  }
  .intro-banner-mobile {
    display: block !important;
  }
}

.ticker-block {
  width: 100%;
  height: 50px;
  margin-top: 2px;
  /* overflow: hidden; */
  position: relative;
  text-align: center;
  padding: 10px 50px;
  vertical-align: center;
  background-color: #000;
  --borderWidth: 2px;
}
.ticker-block::after {
  content: '';
  z-index: -1;
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #fd7822, #f16158, #df45d1, #a166ab, #3199fc, #a166ab, #1098ad, #07b39b, #6fba82);
  animation: animatedgradient 6s ease alternate infinite;
  background-size: 300% 300%;
}
.ticker-block .ticker__element {
  overflow: hidden;
  white-space: nowrap !important;
}
.ticker-wrap {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.desktop-visible,
.mobile-visible {
  display: none;
}
.ticker-text {
  color: #fff;
  display: inline;
  font-size: 24px;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  display: inline-block;
  letter-spacing: -0.02em;
  font-family: 'SFMonoRegular', monospace;
}
@media (min-width: 1080px) {
  .desktop-visible {
    display: block !important;
  }
}
@media (max-width: 1079px) {
  .ticker-block {
    height: 40px;
    padding: 8px 20px;
  }
  .ticker-text {
    font-size: 18px;
    line-height: 24px;
    margin-right: 1.25rem;
  }
  .mobile-visible {
    display: block !important;
  }
  .ticker-wrap {
    -webkit-animation: marquee 50s linear infinite;
    -moz-animation: marquee 50s linear infinite;
    animation: marquee 50s linear infinite;
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.tear-graphic {
  top: 40px;
  left: 50%;
  width: 320px;
  height: 154px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.app-paperhanded-controls {
  display: flex;
  padding-top: 34px;
  align-items: center;
  justify-content: center;
}
.app-paperhanded-tear {
  left: -10px;
  width: 320px;
  height: 154px;
  position: absolute;
}
.button-style {
  margin: 0;
  padding: 8px;
  border-radius: 18px;
  background-color: #191b1f;
}
.button-style li,
.button-style li button {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-style li {
  margin-left: 16px;
}
.button-style li:first-child {
  margin-left: 0;
}
.button-style li button {
  color: #000;
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  background-color: #191b1f;
  border: 1px solid #191b1f;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.button-style li button.no-pointer {
  pointer-events: none;
}
.button-style li button img {
  width: auto;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.button-style li button:hover {
  border-color: #40444f;
}
.button-style li button:hover img,
.button-style li.is--selected button img {
  opacity: 1;
  object-fit: cover;
}
.button-style li.is--selected button {
  color: #fff;
  border-color: #2c2f36;
  background-color: #2c2f36;
}

.app-header {
  color: white;
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.app-header span {
  width: 100%;
  height: 45px;
  max-width: 170px;
  display: flex;
  border-radius: 50px;
  background-color: #3977f0;
}

.red {
  background-color: #f00;
}

.app-link {
  color: #61dafb;
}

.color-grey {
  color: #939bad;
}

.color-blue {
  color: #3976ee;
}
.color-green {
  color: #229b54;
}

.size-26,
.size-40,
.size-48 {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.size-26 {
  width: 26px;
  height: 26px;
}

.size-40 {
  width: 40px;
  height: 40px;
}

.size-48 {
  width: 3rem;
  height: 3rem;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
}

.size-48 img {
  top: 0.438rem; /* 7px */
  position: relative;
}

.rounded {
  border-radius: 0.25rem; /* 4px */
}

.list-none {
  list-style: none;
}

.d-none {
  display: none !important;
}
.d-flex {
  display: flex;
}
.d-inlineflex {
  display: inline-flex;
}
.d-inlineblock {
  display: inline-block;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.text-center {
  text-align: center;
}
.flex-column {
  flex-direction: column;
}

.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 15px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-12 {
  margin-bottom: 18px !important;
}
.mb-30 {
  margin-bottom: 26px !important;
}
.loading {
  border: 1px solid #fff;
  padding: 10px 30px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: center;
}

div.error {
  color: #721c24;
  text-align: center;
  padding: 0.75rem; /* 12px */
  font-size: 0.875rem; /* 14px */
  border-radius: 0.25rem; /* 4px */
  font-style: italic;
  margin-bottom: 30px;
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
  font-family: 'Space Grotesk', monospace;
}

.content {
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  padding-bottom: 0;
  font-family: 'Space Grotesk', monospace;
  font-weight: 600;
}
.content p {
  margin: 0;
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 40px;
  letter-spacing: -1px;
}
.content p.mb-3 {
  margin-bottom: 55px;
}
.content p.mb-2 {
  margin-bottom: 45px;
}
.content p.mb-1 {
  margin-bottom: 20px;
}
.content p span.eth-price {
  font-family: 'Inter', monospace;
  font-weight: 600;
}
.content img.contract-image {
  top: 8px;
  width: 48px;
  height: 48px;
  font-size: 0;
  position: relative;
  display: inline-table;
}
.content img.contract-image.rounded {
  border-radius: 50%;
  border: 1px solid #ffffff;
}

/* Search Bar */
.search-address {
  width: 100%;
  margin: 0 auto;
  max-width: 660px;
  margin-top: 24px;
  position: relative;
}
.search-address .form-control {
  width: 100%;
  color: #ccc;
  height: 44px;
  outline: none;
  border: 0 none;
  font-size: 14px;
  box-shadow: none;
  padding: 10px 12px;
  border-radius: 6px;
  border-radius: 6px;
  padding-left: 40px;
  letter-spacing: -0.04em;
  background-color: #191b1f;
  font-family: 'SFMonoRegular', monospace;
}
.search-address .icon {
  top: 11px;
  left: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: center;
}
.search-address .icon img {
  border-radius: 0.188rem; /* 3px */
}
.search-address .search-icon {
  background-image: url('assets/images/icons/icon-search.svg');
}

.search-address .error {
  margin-top: 24px;
}

/* Footer */
.footer {
  margin-top: 60px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  color: #eef1f6;
  padding-bottom: 50px;
  font-weight: 400;
  font-family: 'Roboto Mono', monospace;
}
a.grey,
a:visited.grey {
  color: #eef1f6;
}
.footer-logo {
  width: 6rem; /* 96px */
  height: 6rem;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.content-inner {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 40px);
}
.content-inner-wrap {
  margin-top: -50px;
}

.content-inner .signin-footer {
  left: 50%;
  width: 100%;
  bottom: 30px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.3;
  color: #eef1f6;
  font-weight: 400;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: 'Roboto Mono', monospace;
}
.content-inner .signin-footer .button-style {
  padding: 0;
}
.content-inner .signin-footer .button-style li button {
  padding: 5px;
}
.content-inner .signin-footer .button-style,
.content-inner .signin-footer .button-style li.is--selected button {
  color: #000;
  border-color: #000;
  background-color: #000;
}
.content-inner .signin-footer .app-paperhanded-controls {
  margin-bottom: 15px;
}

.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}

/* Gradient Border */
.gradient-border {
  width: 200px;
  height: 50px;
  color: #fff;
  display: flex;
  border: 0 none;
  font-size: 24px;
  cursor: pointer;
  --borderWidth: 3px;
  background: #000;
  position: relative;
  border-radius: 8px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  letter-spacing: 0.05rem;
  font-family: 'SFMonoSemibold', monospace;
}
.gradient-border.lg {
  width: 400px;
}
.gradient-border:after {
  content: '';
  z-index: -1;
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #fd7822, #f16158, #df45d1, #a166ab, #3199fc, #a166ab, #1098ad, #07b39b, #6fba82);
  border-radius: calc(3 * var(--borderWidth));
  animation: animatedgradient 6s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mb-4 {
  margin-bottom: 24px;
}

/* Modal */
.modal-window {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 999;
  position: fixed;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
  background-color: rgba(13, 13, 13, 0.75);
}
.modal-open .modal-window {
  opacity: 1;
  visibility: visible;
  pointer-events: auto !important;
}
body.modal-open {
  overflow: hidden !important;
}
.modal-window .modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: none;
}
.modal-window .modal-content {
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 700px;
  /* padding: 35px 95px; */
  padding: 35px;
  position: absolute;
  border-radius: 12px;
  background-color: #000000;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* -webkit-transition: all 0.6s ease-in;
  -moz-transition: all 0.6s ease-in;
  -ms-transition: all 0.6s ease-in;
  -o-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in; */
  overflow-y: auto;
  max-height: calc(80vh);
}
#modalBodyCanvas {
  width: 100%;
}
#modalBodyCanvas > canvas {
  max-width: 200px;
}

.footer-social {
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}
.footer-social-link {
  width: 30px;
  height: 30px;
  display: flex;
  margin: 0 12px;
  overflow: hidden;
  align-items: center;
}
.footer-social-link .twitter-icon,
.footer-social-link .discord-icon,
.modal-window .twitter-icon,
.modal-window .discord-icon {
  width: 30px;
  height: 30px;
  background-size: 30px auto;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.footer-social-link .twitter-icon,
.modal-window .twitter-icon {
  background-image: url('assets/images/icons/icon-twitter.svg');
}
.footer-social-link .discord-icon,
.modal-window .discord-icon {
  background-image: url('assets/images/icons/icon-discord.svg');
}
.footer-social-link .twitter-icon:hover,
.footer-social-link .discord-icon:hover {
  opacity: 0.65 !important;
}

.signin-text {
  font-size: 30px;
  line-height: 1;
  margin-top: -20px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
}
.content .new-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: lex-start;
  /*min-height: calc(100vh - 630px);*/
  min-height: calc(100vh - 330px);
}

/* Table */
.table-responsive {
  width: 100%;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  background-color: transparent;
  font-family: "VCR_OSD_MONO_1_001", sans-serif !important;
}
.table thead th {
  cursor: pointer;
  color: #E8EBED;
  vertical-align: middle;
  text-transform: uppercase;
  border-top: 2px solid #363845;
}
.table td, .table th {
  color: #E8EBED;
  padding: .65rem .5rem;
  text-align: center;
  vertical-align: middle;
  font-family: "VCR_OSD_MONO_1_001", sans-serif !important;
}
.table thead th,
.table tbody td {
  font-size: 17px !important;
  font-weight: 400 !important;
}
.table thead th:hover {
  color: #FEDB67;
}
.table td {
  color: #CCD2D6;
}
.table thead th {
  padding: .75rem .6rem;
  text-transform: uppercase;
}

.table thead .sorting,
.table thead .sorting-asc,
.table thead .sorting-desc {
  background-repeat: no-repeat;
  background-position:  center right;
  background-size: 17px auto;
  padding-right: 20px !important;
}
.table thead .sorting {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:.65'%3E%3Cpath d='m15.6 5.2-8 8h16l-8-8zm0 22 8-8h-16l8 8z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
.table thead .sorting:hover,
.table thead .sorting:focus,
.table thead .sorting:active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:1'%3E%3Cpath d='m15.6 5.2-8 8h16l-8-8zm0 22 8-8h-16l8 8z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

.table thead .sorting-asc {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:.65'%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FEDB67' d='m15.6 5.2-8 8h16z'/%3E%3C/g%3E%3C/svg%3E");
}
.table thead .sorting-asc:hover,
.table thead .sorting-asc:focus,
.table thead .sorting-asc:active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:1'%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FEDB67' d='m15.6 5.2-8 8h16z'/%3E%3C/g%3E%3C/svg%3E");
}

.table thead .sorting-desc {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:.65'%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FEDB67' d='m7.6 19.2 8 8 8-8z'/%3E%3C/g%3E%3C/svg%3E");
}
.table thead .sorting-desc:hover,
.table thead .sorting-desc:focus,
.table thead .sorting-desc:active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg style='opacity:1'%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FEDB67' d='m7.6 19.2 8 8 8-8z'/%3E%3C/g%3E%3C/svg%3E");
}

.table tbody td .name {
  width: 100%;
  cursor: pointer;
  max-width: 250px;
  text-align: left;
  display: inline-block;
  text-transform: uppercase;
}
.table thead th,
.table thead .sorting,
.table thead .sorting-asc,
.table thead .sorting-desc,
.table tbody tr {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.table tbody tr:hover {
  background-color: #0E0F11;
}
.text-green {
  color: #1DD1A1;
}
.text-red {
  color: #DE5A5A;
}
.text-upper {
  text-transform: uppercase;
}

.size-12 {
  width: 10px;
  height: 10px;
  display: flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
}
.size-12 img {
  width: 10px;
  height: 10px;
  object-fit: cover;
}
.size-10 {
  width: 10px;
  height: 14px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
}
.size-10 img {
  width: 10px;
  height: 14px;
  object-fit: cover;
}

.logo-column {
  width: 36px;
  max-width: 36px;
}

.size-24 {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-24 img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.logo-column .size-24 {
  overflow: hidden;
}
.logo-column .size-24 img {
  border-radius: 50%;
}

.ml-auto {
  margin-left: auto !important;
}
.nowrap {
  white-space: nowrap;
}

.content .new-content-wrap {
  padding-top: 40px;
}

.preview-image {
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  html,
  body {
    overflow-x: hidden !important;
  }
  body,
  .content-inner,
  .app-paperhanded {
    max-width: 100%;
  }
  .app-paperhanded-controls {
    padding-top: 34px;
  }
  /* .col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  } */
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content p {
    font-size: 30px;
  }
  .content img.contract-image {
    width: 26px;
    height: 26px;
    top: 3px;
  }
  .size-40,
  .size-48 {
    width: 1.75rem;
    height: 1.75rem;
  }
  .size-48 img {
    top: 0.25rem;
  }
  .content p.mb-3 {
    margin-bottom: 40px;
  }
  .content p.mb-2 {
    margin-bottom: 30px;
  }
  .signin-text {
    font-size: 1.5rem; /* 24px */
    line-height: 1.2;
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-logo {
    width: 6rem; /* 96px */
    height: 6rem; /* 96px */
    margin-bottom: 10px;
  }
  .content .content-inner .content-inner-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 0 !important;
    min-height: calc(100vh - 220px);
    /* max-height: calc(100vh - 220px); */
  }
  .signin-text {
    margin-top: 0 !important;
  }
  .content .new-content {
    min-height: calc(100vh - 570px);
  }
  /* .content {
    min-height: 100vh;
    background-color: #ff0;
  }
  .content .content-inner {
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
  }
  .content-inner .content-inner-wrap {
    margin-top: 0;
    min-height: calc(100vh - 400px);
    background-color: #f00;
  } */
  .content-inner .signin-footer {
    bottom: 0px;
    position: relative !important;
  }
  .content-inner-wrap form {
    width: 100%;
  }
  .search-address {
    width: 100%;
    max-width: 300px;
  }
  .gradient-border {
    font-size: 18px;
  }

  .modal-window .modal-close {
    display: block;
  }
  .modal-window .modal-content {
    top: 50%;
    left: 50%;
    padding: 20px 20px;
    width: calc(100% - 30px);
    /* max-width: calc(450px - 30px); */
    max-width: 360px;
    position: absolute;
    border-radius: 12px;
    background-color: #000000;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  /* .modal-window .modal-content #modalBodyCanvas {
    max-height: 150px;
  } */

  #modalBodyCanvas,
  #modalBodyCanvas > canvas {
    /* max-width: calc((100vw / 2)); */
    max-width: 200px;
  }
  #modalBodyCanvas {
    margin: 0 auto;
  }
  .modal-content .gradient-border {
    width: 170px;
    height: 45px;
  }
  .modal-content .gradient-border.lg {
    width: 260px;
    height: 45px;
    font-size: 18px;
  }
  .modal-window .twitter-icon,
  .modal-window .discord-icon {
    width: 22px;
    height: 22px;
    background-size: 22px auto;
  }
  .content .new-content-wrap {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    overflow-x: scroll;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
  }

  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:mr-auto {
    margin-right: auto !important;
  }
  .sm\:mt-14 {
    margin-top: 14px !important;
  }
  .preview-image {
    max-width: 240px;
  }
}
@media (-webkit-min-device-pixel-ratio: 3) {
  body,
  .content-inner,
  .app-paperhanded {
    width: 95%;
    max-width: 95%;
  }
}

@media (max-width: 1024px) {
  .table thead th, .table tbody td {
    font-size: 14px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 8px !important;
    padding-right: 12px !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
  }
  .table thead .sorting,
  .table thead .sorting-asc,
  .table thead .sorting-desc {
    background-size: 16px auto;
  }
  .table td:first-child, .table th:first-child {
    padding-left: 8px !important;
  }
  .table td:last-child, .table th:last-child {
    padding-right: 20px !important;
  }
  .table .name {
    width: 100px;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }
}

/* @media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
} */

@media (max-width: 1199px) {
  .md\:flex-col {
    flex-direction: column;
  }
  .max-w200 {
    width: 200px;
    text-align: right;
    justify-content: center;
  }
}

@media (max-width: 1299px) {
  .app-paperhanded-controls {
    padding-top: 34px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
  }
  .tear-graphic {
    width: 300px;
    height: 144px;
  }
  .app-paperhanded-tear {
    left: auto;
    width: 300px;
    height: 144px;
    position: relative;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
  .content .new-content-wrap {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    overflow-x: scroll;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
  }
  .table td, .table th {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .content .new-content-wrap {
    margin-left: -20%;
    margin-right: -20%;
    width: calc(100% + 40%);
  }
  .table thead th,
  .table tbody td {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .content .new-content-wrap {
    margin-left: -25%;
    margin-right: -25%;
    width: calc(100% + 50%);
  }
  .table thead th,
  .table tbody td {
    font-size: 16px !important;
  }
}

@media (min-width: 1601px) {
  .content .new-content-wrap {
    /* margin: 0 auto;
    max-width: 1500px; */
    margin-left: -15%;
    margin-right: -15%;
    width: calc(100% + 30%);
  }
}
